// widget系で共通で利用するscssの定義
@import "./../../assets/sass/variables.scss";

.fade_in {
  animation-name: fadeInAnime;
  animation-fill-mode: backwards;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-delay: 1.5s;
  animation-direction: normal;
}
@keyframes fadeInAnime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
