@import "./../../assets/sass/variables.scss";
.modal-info-icon {
  color: $primaryColor;
}

.ui.modal {
  .ui.header {
    display: flex;
    align-items: center;
    span {
      color: $blackColor;
    }
  }
  .modal-content {
    font-size: 1.6rem !important;
    white-space: pre-wrap;
    color: $blackColor;
  }
}

.ui.dimmer.modals.visible.active.portal-modal {
  // dimmerとかぶっても最前面に来るよう調整
  z-index: 1010;
  position: fixed;
}
