@import "./../../assets/sass/variables.scss";

.custom_table {
  .table_header:hover {
    opacity: 0.9;
  }
  .ui.table {
    font-size: 1.2rem;
    margin-top: 2rem;
    .cursor_hand {
      cursor: pointer;
    }
    th {
      padding: 0.8rem !important;
    }
    tr:hover {
      background-color: $hoverBackgroundColor;
    }
    .align_center {
      text-align: center;
    }
  }
  .ui.sortable.table {
    thead {
      th {
        color: #ffffff;
        background-color: #023893;
      }
      th:hover {
        color: #ffffff;
        background-color: #023893;
      }
    }
  }
  .information {
    margin: 1rem;
    font-size: 1.5rem;
  }
  .custom_table-reload {
    text-align: right;
  }
}

.custom_table_pagination {
  text-align: center;
  margin: 2rem 0;
  a {
    font-size: 1.2rem;
  }
}

.custom_modal.ui.modal {
  .content {
    font-size: 1.6rem;
    padding: 3rem !important;
  }
  .header {
    font-size: 1.6rem !important;
  }
}

.question_message {
  font-size: 1.6rem;
  text-align: center;
}

.custom_progress {
  margin: 1rem 0;
  .progress_bar {
    margin: 0 !important;
  }
  .progress_count {
    margin: 0;
    font-size: 1rem;
  }
  .ui.progress .bar > .progress {
    white-space: nowrap;
    position: relative;
    font-size: 0.9em;
    padding: 0.35rem 0;
    top: auto;
    right: auto;
    margin: 0;
    text-align: center;
  }
}

.common_breadcrumb {
  .breadcrumb_divider {
    color: $linkTextColor;
  }
}

.common_breadcrumb {
  font-size: 1.2rem;
  margin: 1rem 0;
  line-height: 1.8rem;
}

.custom_input.vertical {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

.custom_input {
  display: grid;
  grid-template-columns: 12rem 1fr;
  align-items: center;
  font-size: 1.2rem;
  margin: 1rem 1rem;
  // overflow: hidden;
  .input_body {
    padding: 0 1rem;
    position: relative;
    > label.auto-complete-off {
      width: 2px;
      height: 2px;
      position: absolute;
      opacity: 0;
    }

    div.autocomplete-off {
      > input::-webkit-contacts-auto-fill-button,
      > input::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }
    .checkbox_wrapper {
      display: flex;
      align-items: center;
      min-height: 2rem;
      margin: 1rem 0;
    }
    .radio_wrapper.vertical {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
    }
    .radio_wrapper {
      display: flex;
      align-items: center;
      min-height: 2rem;
      margin: 1rem 0;
      .radio {
        label {
          font-size: 1.2rem;
        }
        margin: 0 1.5rem 0 0rem;
      }
    }
  }
  .input_body.flex_layout {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 1rem;
  }
  .input_body.unset_min {
    input {
      min-width: unset;
    }
  }

  .input_textarea_disabled {
    opacity: 0.45;
  }

  .input_body.dropdown {
    height: 4rem;
  }
  .input_element {
    min-width: 30rem;
    width: 100%;
  }
  .size_tiny {
    min-width: 15rem !important;
    width: 15rem !important;
  }
  .size_small {
    min-width: 20rem !important;
    width: 20rem !important;
  }
  .size_medium {
    min-width: 30rem !important;
    width: 30rem !important;
  }
  .size_large {
    min-width: 50rem !important;
    width: 50rem !important;
  }
  .size_extra_large {
    min-width: 80rem !important;
    width: 80rem !important;
  }
  .size_auto {
    min-width: 0rem !important;
  }
  textarea.input_element {
    font-size: 1.2rem !important;
  }
  .input_password_visible {
    position: absolute;
    cursor: pointer;
    right: 1.2rem;
    top: 1.2rem;
  }
}

.custom_input.custom_input_nolabel {
  grid-template-columns: 1fr;
}

.custom_input.copy_cripboard {
  position: relative;
  grid-template-columns: 12rem 1fr 6.5rem;
  .input_element {
    min-width: 20rem;
  }
  .copy_button_area {
    display: grid;
    align-items: center;
    .copy_button {
      border: 0.1rem solid $grey;
      border-radius: 0.3rem;
      width: 5rem;
      cursor: pointer;
      padding: 0.2rem;
      p {
        color: $grey;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
      }
    }
    .copy_button:hover {
      background-color: $hoverBackgroundColor;
      p {
        font-weight: 600;
      }
    }
  }
  .copy_message {
    position: absolute;
    color: $infoFontSecondaryColor;
    bottom: -0.5rem;
    right: 0.5rem;
    font-size: 1rem;
  }
}

.custom_input_mode_checkbox {
  display: grid;
  grid-template-columns: 1fr 16rem;
  align-items: center;
  font-size: 1.2rem;
  margin: 1rem 1rem;
  justify-content: flex-end;
  text-align: right;
  .input_body {
    padding: 0 1rem;
    position: relative;
    .checkbox_wrapper {
      display: flex;
      align-items: center;
      min-height: 2rem;
      margin: 0;
      justify-content: flex-end;
    }
    .radio_wrapper {
      display: flex;
      align-items: center;
      min-height: 2rem;
      margin: 1rem 0;
      .radio {
        label {
          font-size: 1.2rem;
        }
        margin: 0 1.5rem 0 0rem;
      }
    }
  }
  .label {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
  .label_disabled {
    opacity: 0.5;
    cursor: default;
    label {
      cursor: default;
    }
  }
}

.custom_readonly {
  display: grid;
  grid-template-columns: 12rem 1fr;
  align-items: center;
  font-size: 1.2rem;
  margin: 1rem 1rem;
  overflow: hidden;
  min-height: 4rem;
  .input_body {
    padding: 0 1rem;
  }
}

.custom_readonly.copy_cripboard {
  min-height: 4.75rem;
  .input_body {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 5.5rem;
    .copy_button_area {
      display: grid;
      align-items: center;
      .copy_button {
        border: 0.1rem solid $grey;
        border-radius: 0.3rem;
        width: 5rem;
        cursor: pointer;
        padding: 0.2rem;
        p {
          color: $grey;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
        }
      }
      .copy_button:hover {
        background-color: $hoverBackgroundColor;
        p {
          font-weight: 600;
        }
      }
    }
    .copy_information {
      position: absolute;
      color: $infoFontSecondaryColor;
      bottom: -1.5rem;
      right: 0.5rem;
      font-size: 1rem;
    }
  }
}

.ui.selection.dropdown.dropdown_element {
  font-size: 1.2rem;
  padding: 1.32rem 1.2rem;
  margin: 0;
  min-width: 30rem;
  width: 100%;
  min-height: 4rem;
  .icon {
    padding: 1rem;
  }
  .menu {
    .item {
      padding: 1.2rem 1.1rem !important;
      font-size: 1.2rem;
    }
  }
  > input.search {
    padding: 1.2rem 1.2rem !important;
  }
}

.ui.selection.dropdown.multiple_dropdown_element {
  font-size: 1.2rem;
  padding: 0.8rem 1.2rem;
  margin: 0;
  min-width: 30rem;
  width: 100%;
  min-height: 4rem;
  .menu {
    .item {
      padding: 1.2rem 1.1rem !important;
      font-size: 1.2rem;
    }
  }
}

.ui.selection.dropdown.dropdown_element.loading {
  > i.icon {
    padding: 2rem 1.5rem !important;
  }
}

.limit_dropdown {
  display: grid;
  grid-template-columns: 6rem auto;
  align-items: center;
  justify-content: flex-end;
  .label {
    p {
      font-size: 1.2rem;
    }
  }
  .ui.selection.dropdown.limit_dropdown_element {
    font-size: 1.2rem;
    padding: 0.8rem 1.2rem;
    margin: 0;
    min-width: 8rem;
    min-height: 2rem;
    .icon {
      padding: 0.6rem;
    }
    .menu {
      .item {
        padding: 1rem !important;
        font-size: 1.2rem;
      }
    }
  }
}
.input_body.mapping {
  .ui.selection.dropdown.mapping_element {
    font-size: 1.2rem;
    padding: 1.32rem 1.2rem;
    margin: 0;
    min-width: 30rem;
    width: 100%;
    min-height: 4rem;
    background-color: #f5f5f5;
    border: none;
    font-size: 1.2rem;
    .icon {
      padding: 1rem;
    }
    .menu {
      .item {
        padding: 1.2rem 1.1rem !important;
        font-size: 1.2rem;
      }
    }
  }
  .mapped_items {
    margin-top: 1rem;
    width: 100%;
    max-height: 15rem;
    overflow: auto;
    .mapping_item {
      display: grid;
      grid-template-columns: 1fr 3rem;
      grid-column-gap: 1rem;
      align-items: center;
      margin: 0.5rem 0;
      padding: 0.3rem 1rem;
      width: 100%;
      &:hover {
        background-color: #f5f5f5;
        .item_remove {
          opacity: 1;
        }
      }

      .item_remove {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        line-height: 1.2rem;
        opacity: 0;
      }
    }
  }
}

// Override date picker css
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 100px;
}
.react-datepicker {
  border: 1px solid $primaryColor;
  font-size: 1.2rem !important;
}

.react-datepicker__header {
  background-color: $primaryColor;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom: 1px solid $primaryColor;
}

.react-datepicker__time-container {
  border-left: 1px solid $primaryColor;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border: none;
}

.react-datepicker__input-container > input {
  margin: 0em;
  min-width: 30rem;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 1rem 1.4em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.4rem;
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  box-shadow: none;
  &::placeholder {
    color: $inputPlaceholderColor;
  }
}

.react-datepicker__input-container > input:disabled {
  opacity: 0.45;
}

.react-datepicker-ignore-onclickoutside::placeholder {
  color: $inputPlaceholderColor;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
  color: #fff;
  font-size: 1.2rem !important;
  line-height: 1.8rem;
}

.required_icon {
  color: red;
  font-size: 2rem;
  padding-left: 5px;
  line-height: 1rem;
}

.statistic.count.has_action {
  cursor: pointer;
}
.statistic.count {
  display: grid;
  grid-template-rows: 4.5rem 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  .count_value {
    font-size: 5.5rem;
    font-weight: 600;
    .denominator {
      font-size: 1rem;
    }
  }
  .label {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .teal {
    color: $teal;
  }
  .blue {
    color: $blue;
  }
  .grey {
    color: grey;
  }
  .black {
    color: black;
  }
}
.statistic.count.primary {
  grid-template-rows: 4.5rem 2rem;
  .count_value {
    font-size: 5rem;
    line-height: 2.5rem;
    .denominator {
      font-size: 1rem;
    }
  }
  .label {
    font-size: 1.2rem;
  }
}
.statistic.count.sp-primary {
  grid-template-rows: 3rem 1.5rem;
  .count_value {
    font-size: 3.5rem;
    .denominator {
      font-size: 1.2rem;
    }
  }
  .label {
    font-size: 1.2rem;
  }
}

.statistic.count.secondary {
  grid-template-rows: 3rem 1rem;

  .count_value {
    font-size: 3rem;
    .denominator {
      font-size: 1.5rem;
    }
  }
  .label {
    font-size: 1rem;
  }
}

.statistic.level {
  display: grid;
  grid-template-rows: 2.5rem 6rem;
  color: $pink;
  align-items: center;
  justify-content: center;
  text-align: center;
  .value {
    font-size: 7.5rem;
    font-weight: 600;
  }
  .label {
    font-size: 1.28rem;
    font-weight: 600;
  }
  .label.bottom_label {
    margin-top: 2rem;
  }
}
.statistic.level.mini {
  grid-template-rows: 5rem 1rem;
  .value {
    font-size: 5rem;
  }
}

.statistic_percent {
  display: grid;
  grid-template-columns: 1fr 1rem;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: baseline;
  .value {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: right;
    .studied_color {
      color: $studiedColor;
    }
    .studying_color {
      color: $studyingColor;
    }
    .not_study_color {
      color: $notStudyColor;
    }
  }
  .unit {
    font-size: 1rem;
  }
}

.ui.card {
  .ribbon_header {
    .ui.ribbon.label {
      left: -1.5rem;
    }
    position: absolute;
  }
}
.card_progress {
  .horizontal_custom_progress {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    .left {
      padding: 1.5rem;
    }
    .right {
      margin-top: 1.5rem;
    }
  }
  .vertical-custom_progress {
    padding: 2rem 0rem 0rem;
    display: grid;
    grid-template-rows: auto 10rem;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    .top {
      .icon_flag {
        margin-right: 0rem;
      }
      .icon_walk {
        margin-right: 0rem;
        margin-left: -0.5rem;
      }
    }
    .bottom {
      padding: 2rem 0rem;
      .status_count {
        grid-template-columns: auto 0.5rem auto 0.5rem auto;
        .statistic_count_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .vertical-custom_progress.sp_custom_progress {
    padding: 0rem;
    .top {
      padding: 0rem 3rem;
    }
    .bottom {
      padding: 0.5rem 0rem 0rem;
    }
  }
  .status_count {
    display: grid;
    grid-template-columns: 15rem 1fr 15rem 1fr 15rem;
    grid-template-rows: 6rem;
    .arrow {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      .left_arrow {
        width: 0%;
        height: 0%;
        border-top: 1rem solid transparent;
        border-right: 1rem solid #d9d9d9;
        border-bottom: 1rem solid transparent;
      }
    }
  }
  .sp_custom_progress.status_count {
    grid-template-columns: 1fr auto 1fr auto 1fr;
  }
}

.custom_progress_with_walk_icon {
  .dual_progress {
    position: relative;
    .secondary_progress {
      position: absolute;
      top: 0;
      background-color: $barPink;
      height: 4rem;
      border-radius: 0.4rem;
      animation: progress 1s normal;
      animation-fill-mode: forwards;
    }
    .ui.progress.single_digit {
      .bar {
        .progress {
          font-size: 1.2rem;
          font-weight: 600;
          color: white;
          margin-right: -0.5rem;
        }
      }
    }
    .ui.progress.multi_digit {
      .bar {
        .progress {
          font-size: 1.4rem;
          font-weight: 600;
          color: white;
          margin-right: 0.5rem;
        }
      }
    }
    .ui.progress {
      margin: 0;
      height: 4rem;
      .bar {
        height: 4rem;
        z-index: 10;
        animation: progress 1s normal3s;
        animation-fill-mode: forwards;
      }
    }
  }
  .icon_on_progress {
    margin-bottom: -0.8rem;
    position: relative;
    height: 4rem;
    .icon_walk {
      text-align: right;
      width: 0%;
      position: absolute;
      margin-right: -2.5rem;
      animation: progress 1s normal;
      animation-fill-mode: forwards;
    }
    .icon_walk.finished {
      opacity: 0;
      animation: progress_finished 1s backwards;
    }
    .icon_flag {
      text-align: right;
      margin-right: -2rem;
    }
    .icon_flag.finished {
      text-align: right;
      margin-right: -2rem;
      .finished_flag_icon {
        top: -1.4rem;
        right: 2.3rem;
        position: absolute;
        transform: scale(-1, 1) rotate(45deg);
        animation: finish_open 1s normal;
        animation-fill-mode: forwards;
      }
      .finished_people_icon {
        animation: finish_open 1s normal;
        animation-fill-mode: forwards;
        position: absolute;
      }
      .finished_first_icon {
        animation: finish_close 1s normal;
        animation-fill-mode: forwards;
      }
    }
  }
  @keyframes progress {
    from {
      width: 0%;
    }
  }
  @keyframes progress_finished {
    from {
      width: 0%;
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes finish_open {
    from {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes finish_close {
    from {
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.custom_progress_icon_and_medal {
  display: grid;
  grid-gap: 0.5rem;
  .gradation_progress {
    position: relative;
    .primary_progress {
      position: absolute;
      top: 0;
      // background-color: $barPink;
      background: linear-gradient(0.25turn, rgba(2, 56, 147, 1), rgba(0, 160, 233, 1));
      height: 2rem;
      border-radius: 0.4rem;
      border-radius: 6.3rem;
      animation: progressSlide 1s normal 0.5s;
      animation-fill-mode: forwards;
    }
    .progress_background {
      background-color: #cccccc;
      width: 100%;
      height: 2rem;
      border-radius: 6.3rem;
    }
  }
  .icon_on_progress {
    position: relative;
    height: 4rem;
    .progress_balloon {
      display: flex;
      justify-content: flex-end;
      width: 0%;
      position: absolute;
      margin-top: 1rem;
      margin-left: 2rem;
      animation: progressSlide 1s normal 0.5s;
      animation-fill-mode: forwards;
      .progress_balloon_content {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-gap: 0.1rem;
        z-index: 10;
        .balloon {
          opacity: 0;
          animation: percentfadeIn 0.5s normal 1s;
          animation-fill-mode: forwards;
          position: relative;
          .balloon_content {
            display: grid;
            grid-template-columns: 1fr 1rem;
            background-color: $brandSecondaryColor;
            padding: 0.25rem 0.35rem 0.1rem 0.35rem;
            grid-gap: 0.3rem;
            border-radius: 0.3rem;
            align-items: center;
            .percent {
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                color: white;
                font-size: 2rem;
                line-height: 1.5rem;
                font-weight: 600;
                margin: 0;
                span {
                  font-size: 1.2rem;
                  font-weight: 600;
                }
              }
            }
          }
          .balloon_shape_wrap {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            .balloon_shape {
              position: absolute;
              height: 1rem;
              width: 0.5rem;
              border: 0.3rem solid transparent;
              border-top: 0.3rem solid $brandSecondaryColor;
            }
          }
        }
        .smile_icon {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 4.2rem;
          height: 4.2rem;
          margin: 0 auto;
          z-index: 10;
        }
        .smile_icon.contents_view_label {
          width: 5.2rem;
          .contents_view_label {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
      }
    }
    .medal {
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: flex-end;
    }
  }
  @keyframes percentfadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.pink {
  color: $pink;
}

.theme_item {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 22rem 1fr;
  padding: 1.5rem 1rem;
  cursor: pointer;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    .thumbnail_wrapper {
      height: 12rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid $thumbnailBorderColor;
    }

    .thumbnail {
      width: 100%;
    }
  }

  .right {
    padding: 0 0 0 2rem;

    .item_header {
      display: grid;
      grid-template-columns: 1fr 25rem;
      margin-bottom: 0.5rem;

      .status {
        display: grid;
        grid-template-columns: 1fr 8rem;
        text-align: right;
        float: right;
        justify-content: flex-start;
        align-items: flex-start;

        .progress p {
          font-size: 1.2rem;
          margin: 0;
        }
      }
    }
  }

  .info {
    display: grid;
    text-align: left;
    grid-template-rows: 6rem 1fr;

    .title {
      font-size: 1.6rem;
      line-height: 2.6rem;
      font-weight: 600;
    }

    .description {
      color: $descriptionColor;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-all;
      display: -webkit-box;
      font-size: 1.2rem;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
}

.theme_item:hover {
  background-color: $hoverBackgroundColor;
}

.theme_item.studied_item {
  background-color: rgb(117 117 117 / 10%);
  opacity: 0.7;
}

.pc-contents_item.one_action {
  cursor: pointer;
  &:hover {
    background-color: $hoverBackgroundColor;
  }
}

.pc-contents_item {
  position: relative;
  .contents_item {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 22rem 1fr;
    padding: 1.5rem 1rem;
    cursor: pointer;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .thumbnail_wrapper {
        position: relative;
        height: 12rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid $thumbnailBorderColor;
        width: 100%;
        .duration {
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          border-radius: 0.2rem;
          padding: 0.1rem 0.4rem;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .thumbnail {
        width: 100%;
      }
    }

    .right {
      padding: 0 0 0 2rem;
      height: 12rem;

      .item_header {
        display: grid;
        grid-template-columns: 1fr 15rem;
        margin-bottom: 0.5rem;

        .status {
          display: flex;
          text-align: center;
          justify-content: flex-start;
          align-items: flex-start;
          justify-self: right;
          gap: 1rem;
        }
      }
    }

    .info {
      display: grid;
      text-align: left;
      grid-template-rows: 1fr 7rem;

      .title {
        font-size: 1.4rem;
        line-height: 2.6rem;
        margin-bottom: 0rem;
        font-weight: 600;
      }
      .theme_name {
        font-size: 1rem;
        color: $descriptionColor;
        margin-bottom: 0rem;
      }

      .description_area {
        .description {
          height: 100%;
          color: $descriptionColor;
          overflow: hidden;
          white-space: pre-wrap;
          word-break: break-all;
          display: -webkit-box;
          font-size: 1.2rem;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
      }
    }
  }
}

.sp-contents_item_header {
  .item_custom_header {
    color: $mypageTitleColor;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 2rem;
    text-align: left;
    font-weight: 600;
    font-size: 1.6rem;
  }
}

.sp-contents_item_card.one_action {
  margin: 1rem 0;
  cursor: pointer;
  &:hover {
    background-color: $hoverBackgroundColor;
  }
  .sp-contents_item {
    .item {
      .item_body {
        padding: 0.5rem 0rem 1.5rem 0rem;
      }
    }
  }
}

.sp-contents_item_card {
  box-shadow: none;
}

.sp-contents_item {
  padding: 0rem;
  .item {
    display: grid;
    grid-template-rows: minmax(1rem, max-content) 1fr;
    padding: 0 1.5rem;

    .item_body {
      display: grid;
      grid-template-columns: 12.5rem 1fr;
      margin: 0rem;
      padding: 0.5rem 0rem 0rem 0rem;
      .thumbnail {
        position: relative;
        .duration {
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          border-radius: 0.2rem;
          padding: 0.1rem 0.4rem;
          font-size: 1rem;
          font-weight: 600;
          margin: 0 auto;
        }
      }
      .thumbnail_wrapper {
        position: relative;
        .status {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0.5rem;
        }
      }
    }

    .item_name {
      text-align: left;
      padding: 1.5rem 0rem 0.5rem 0rem;
      display: grid;
      align-items: center;
      p {
        font-size: 1.4rem;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 0rem;
      }
      p.theme_name {
        font-size: 1rem;
        font-weight: 200;
        color: $descriptionColor;
      }
      .item_custom_header {
        p {
          font-size: 1.2rem;
          color: $descriptionColor;
        }
      }
    }
    .item_status {
      grid-template-columns: 1fr auto;
      column-gap: 0.5rem;
      align-items: flex-start;
    }

    .info_description {
      text-align: left;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-all;
      max-height: 8rem;
      padding-left: 1rem;
      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
    }
    .sp-contents-button_area {
      margin: 1rem 0rem;
      .action_content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0rem;
        .button.ui {
          width: 100%;
          margin: 0rem;
        }
      }
      .action_test {
        display: flex;
        justify-content: center;
        align-items: center;
        .button.ui {
          width: 100%;
          margin: 0rem;
        }
      }
    }
    .sp-contents-button_area.use_test {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
}

.custom_theme_report_item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 1fr 3rem;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;
  .theme_title {
    p {
      font-size: 1.2rem;
      font-weight: 600;
    }
    color: $headerColor;
    .link_icon {
      cursor: pointer;
      position: relative;
      margin-left: 0.8rem;
      bottom: 0.2rem;
    }
  }
  .impressions_text {
    border-radius: 1rem;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 2rem;
    font-size: 1.4rem;
    margin: 1rem 0;
    background-color: #0538930d;
  }
  .action {
    text-align: right;
  }
}

.theme_info_wrapper {
  padding: 2rem;
  background-color: $primaryColor;
  color: white;
  margin: 2rem 0rem 3rem 0rem;
  .common_breadcrumb {
    margin: 1em 0rem;
    font-size: 1rem;
    .divider {
      color: white !important;
    }
    a {
      color: white !important;
      border-bottom: solid 0.05rem $linkTextUnderLineColor;
      padding-bottom: 0.15rem;
    }
    a:hover {
      font-weight: 600;
    }
  }
  .theme_header {
    display: grid;
    grid-template-columns: minmax(20rem, max-content) 1fr;
    .progress {
      font-size: 1rem;
      text-align: right;
      p {
        margin: 0;
      }
    }
    .status {
      display: grid;
      grid-template-columns: 1fr 7rem;
      align-items: start;
    }
    .status_label {
      text-align: right;
    }
    .header {
      color: white;
    }
  }

  .theme_info_body {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 15rem 1fr;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .thumbnail_wrapper {
        min-height: 8rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thumbnail {
        width: 100%;
      }
    }
    .right {
      margin: 1rem 1rem 1rem 1.5rem;
      display: grid;
      align-items: center;
      .description {
        white-space: pre-wrap;
        word-break: break-all;
        font-size: 1.2rem;
      }
      .duration_sum {
        font-size: 1.2rem;
      }
    }
  }
}

.latest_login {
  p {
    margin: 0rem;
  }
}

.ui.label.level_label {
  width: 6rem;
  border-radius: 2rem;
}
.level_label.level1_color {
  background-color: $level1Color !important;
  color: $primaryFontColor !important;
}

.level_label.level2_color {
  background-color: $level2Color !important;
  color: $primaryFontColor !important;
}

.level_label.level3_color {
  background-color: $level3Color !important;
  color: $primaryFontColor !important;
}

.level_label.level4_color {
  background-color: $level4Color !important;
  color: $primaryFontColor !important;
}

.level_label.level5_color {
  background-color: $level5Color !important;
  color: $primaryFontColor !important;
}

.level_label.level6_color {
  background-color: $level6Color !important;
  color: $primaryFontColor !important;
}

.dimmed.dimmable.custom_overlay_dimmed {
  overflow: visible;
}
.custom_overlay.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border-radius: 0.5rem;
  z-index: 450;
  .hover_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 2rem;

    button {
      width: 14rem;
    }
  }
}

.page_back_link {
  cursor: pointer;
  font-size: 1.2rem;
}

.header_with_account {
  .header_account_name {
    font-size: 1.2rem;
  }
}

.header_with_user_id {
  .header_user_id {
    font-size: 1.2rem;
  }
}

.pc-question {
  margin-bottom: 5rem;
  > p {
    font-size: 1.5rem;
  }
  .ui_message {
    white-space: pre-wrap;
    font-size: 1.2rem;
    font-weight: 600;
    .question_contents {
      text-align: right;
      display: grid;
      grid-template-columns: 1fr 8.5rem;
      column-gap: 0.5rem;
      align-items: flex-end;
      .question_contents_title {
        font-size: 1.2rem;
        color: $descriptionColor;
        display: grid;
        justify-content: flex-end;
        align-items: baseline;
        text-align: left;
        .label {
          font-weight: normal;
          font-size: 0.8rem;
        }
        .contents_name {
          font-size: 1.2rem;
        }
      }
    }
    .question_contents_unreleased {
      text-align: right;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0.5rem;
      align-items: flex-end;
      .question_contents_title {
        font-size: 1.2rem;
        color: $descriptionColor;
        display: grid;
        justify-content: flex-end;
        align-items: baseline;
        text-align: left;
        .label {
          font-weight: normal;
          font-size: 0.8rem;
        }
        .contents_name {
          font-size: 1.2rem;
        }
        .unrelease_info {
          font-weight: normal;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.pc-question:last-child {
  margin-bottom: 2rem;
}

.question {
  .question_title {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: $brandPrimaryColor;
    white-space: pre-wrap;
    background-color: $brandPrimaryLightColor;
    padding: 1rem;
    border-bottom: 0.1rem $brandPrimaryColor solid;
    .wrong_highlight {
      color: $systemTextErrorColor;
      border-bottom: solid 0.1rem $systemTextErrorColor;
    }
  }
  .question_title.unanswered {
    background-color: $errorBackGroundColor;
    color: $systemTextErrorColor;
    border-bottom: solid 0.1rem $systemTextErrorColor;
  }
  > .answer {
    .item {
      padding: 0.6rem 0.5rem 0.5rem 1.1rem !important;
      border-radius: 0.4rem;
      margin: 0.3rem 0;
      font-size: 1.8rem;
      label:before {
        border-color: $grayDarkColor !important;
        border-width: 0.15rem;
      }
      .choice_image {
        margin: 0.5rem 0rem;
      }
      .checkbox {
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
    .list_checked {
      padding: 0.5rem 0.5rem 0.5rem 1rem !important;
      background-color: $brandSecondaryLightColor;
      border: 1px $brandSecondaryColor dashed;
      font-weight: 600;
      label:before {
        border-color: $brandSecondaryColor !important;
        border-width: 0.15rem;
      }
      label:after {
        background-color: $brandSecondaryColor !important;
        color: white !important;
      }
    }
    .list_checked.incorrect {
      border: 1px $systemTextErrorColor dashed;
      background-color: $systemBackgroundColor;
    }
    .radio {
      width: 100%;
      font-size: 1.5rem;
    }
    .checkbox {
      width: 100%;
      font-size: 1.5rem;
    }
  }
  .question_title_header {
    display: grid;
    grid-template-columns: auto 1fr 7rem;
    justify-content: space-between;
    .badge {
      padding: 0.5rem 1rem;
      background-color: $brandPrimaryColor;
      display: inline-block;
      border-radius: 0.4rem 0.4rem 0rem 0rem;
      p {
        color: white;
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        span {
          padding: 0rem 0rem 0rem 0.5rem;
          font-size: 2rem;
        }
      }
    }
    .unanswered_label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 1rem;
      color: $systemTextErrorColor;
      p {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
      }
      .unanswered_icon {
        font-size: 2.4rem;
        margin: 0 0.25rem;
      }
    }
    .correct_icon {
      > div {
        display: grid;
        grid-template-columns: 2rem 5rem;
        grid-gap: 0.5rem;
        align-items: center;
        justify-content: center;
        p {
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
      .correct {
        color: $brandSecondaryColor;
      }
      .incorrect {
        color: $systemTextErrorColor;
      }
    }
  }
  .question_title_header.unanswered {
    .badge {
      background-color: $systemTextErrorColor;
      p {
        color: white;
      }
    }
  }
  > .correct_message {
    box-shadow: none;
    font-size: 1.6rem;
    background-color: $brandPrimaryLightColor;
    font-weight: normal;
    padding: 1.5rem;
  }
  > .incorrect_message {
    box-shadow: none;
    font-size: 1.6rem;
    background-color: $systemBackgroundColor;
    color: $systemTextErrorColor;
    font-weight: normal;
    padding: 1.5rem;
  }
}

.sp-question {
  margin: 1rem 1.5rem 5rem 1.5rem;
  > .answer {
    margin-top: 1.5rem;
  }
  .ui_message {
    font-size: 1.2rem;
    font-weight: 600;
    white-space: pre-wrap;
    .question_contents {
      text-align: right;
      display: grid;
      grid-template-columns: 1fr 8.5rem;
      column-gap: 0.5rem;
      align-items: flex-end;
      .question_contents_title {
        font-size: 1rem;
        color: $descriptionColor;
        display: grid;
        justify-content: flex-end;
        align-items: baseline;
        text-align: left;
        .label {
          font-weight: normal;
          font-size: 0.8rem;
        }
        .contents_name {
          font-size: 1.2rem;
        }
      }
    }
    .question_contents_unreleased {
      text-align: right;
      display: grid;
      grid-template-columns: 1fr 8.5rem;
      column-gap: 0.5rem;
      align-items: flex-end;
      .question_contents_title {
        font-size: 1.2rem;
        color: $descriptionColor;
        display: grid;
        justify-content: flex-end;
        align-items: baseline;
        text-align: left;
        .label {
          font-weight: normal;
          font-size: 0.8rem;
        }
        .contents_name {
          font-size: 1.2rem;
        }
        .unrelease_info {
          font-weight: normal;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.sp-question:last-child {
  margin: 0 1.5rem 2rem 1.5rem;
}

.bar_chart_label {
  font-size: 1.6rem;
  font-weight: 600;
}
.bar_chart_percent {
  font-size: 1.2rem;
}

.notfound_message {
  text-align: left;
  .title {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 1rem 0;
  }
  .body {
    font-size: 1.2rem;
    margin: 1.5rem 0 2rem 0;
  }
}

.notfound_message.sp-notfound_message {
  padding: 2rem 2rem;
}

.released_item {
  cursor: pointer;
}

.closed_item {
  opacity: 0.5;
}

.pc-simple_contents_item {
  // float: left;
  // width: 24rem;
  // margin: 1rem;
  display: grid;

  width: 100%;
  border-radius: 0.6rem;
  box-shadow: 0 0 0.4rem 0.1rem #cccccc;
  overflow: hidden;
  cursor: pointer;
  .image {
    border-radius: 0.5rem;
  }
  .item_card {
    max-width: 100%;
    position: relative;
    display: grid;
    flex-direction: column;
    min-height: 0px;
    background: #ffffff;
    padding: 0em;
    border: none;
    border-radius: 0.4rem;
    column-gap: 1rem;
    grid-template-rows: 15rem auto;
    .item_title {
      padding: 1rem;
    }
  }
  .item_card:hover {
    background-color: $monoGrayWhitish;
  }
  .item_header {
    font-size: 1.2rem;
    font-weight: 300;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .item_information {
    display: grid;
    grid-template-columns: 1fr 5rem;
    align-items: flex-end;
    p {
      font-size: 1.2rem;
      margin: 0.5rem 0;
    }
    .status {
      text-align: right;
    }
  }

  .thumbnail_wrapper {
    position: relative;
    display: block;
    flex: 0 0 auto;
    padding: 0em;
    background: $hoverBackgroundColor;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    overflow: hidden;
    .thumbnail {
      display: flex;
      align-items: center;
      // max-height: 13.5rem;
      overflow: hidden;
      // border-radius: 0.5rem;
      .image {
        img {
          display: block;
          width: 100%;
          height: auto;
          // border-radius: inherit;
        }
      }
    }
    .duration {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      border-radius: 0.2rem;
      padding: 0.1rem 0.4rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .progress {
      width: 100%;
      background-color: $grey;
      height: 0.5rem;
      position: absolute;
      bottom: 0;
    }
    .bar {
      width: 50%;
      background-color: $orange;
      height: 0.5rem;
      position: absolute;
      bottom: 0;
    }
    .progress_point {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.5rem;
      position: absolute;
      bottom: -0.1rem;
      background-color: $orange;
    }
  }
}

.pc-simple_layount_grouping_contents_list {
  width: 85rem;
  .simple_layout_accordion {
    overflow: hidden;
    margin: 3rem 0;
    .title {
      display: grid;
      grid-template-columns: 2rem auto;
      justify-content: left;
      align-items: center;
      white-space: pre-wrap;
      background-color: $titleBackgroundColor;
      padding: 1rem !important;
      border-left: 0.3rem #767676 solid;
    }
    .simple_layout_title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .content {
      margin-top: 1rem;
      display: grid !important;
      grid-template-columns: repeat(3, minmax(20rem, 1fr));
      grid-template-rows: auto;
      grid-gap: 1.5rem;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 3rem;
    }
  }
  .simple_layount_category_menu.ui.secondary.menu {
    justify-content: left;
    padding: 1.5rem 1rem 1.5rem 1rem;
    margin: 0rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    row-gap: 1rem;
    a.item.active {
      color: white;
      background-color: #767676;
      border: none;
    }
    a.item {
      display: block;
      font-size: 1.2rem;
      border: 1px solid #d4d4d4;
      border-radius: 1rem;
      padding: 1rem 0.8rem;
      min-width: 8.5rem;
      text-align: center;
      font-weight: 600;
      background-color: white;
      color: #8a8a8a;
      margin: 0rem;
    }
  }
  .simple_layount_grouping_contents_list.no_data {
    grid-template-columns: 1fr;
  }
  .simple_layount_grouping_contents_list {
    width: 85rem;
  }
}

.sp-simple_layount_grouping_contents_list {
  .simple_layount_category_menu.ui.secondary.menu {
    justify-content: left;
    padding: 1rem 2rem 1rem 2rem;
    margin: 0rem;
    column-gap: 1rem;
    overflow-x: scroll;
    max-width: 100vw;
    a.item.active {
      color: white;
      background-color: #767676;
      border: none;
    }
    a.item {
      display: block;
      font-size: 1.2rem;
      border: 1px solid #d4d4d4;
      border-radius: 1rem;
      padding: 1rem 0.8rem;
      min-width: 8.5rem;
      text-align: center;
      font-weight: 600;
      background-color: white;
      color: #8a8a8a;
      margin: 0rem;
    }
  }
  .simple_layount_category_menu::-webkit-scrollbar {
    display: none;
  }
  .simple_layout_accordion {
    overflow: hidden;
    margin-bottom: 1.5rem;
    .title {
      display: grid;
      grid-template-columns: 2rem auto;
      justify-content: left;
      align-items: center;
      white-space: pre-wrap;
      background-color: $titleBackgroundColor;
      padding: 1rem !important;
      border-left: 0.3rem #767676 solid;
    }
    .content {
      padding-top: 1rem !important;
    }
    .account_original_title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .contents_item {
      .item_header {
        font-size: 1.2rem;
        font-weight: 300;
      }
    }
  }
}

.sp-simple_contents_item {
  display: grid;
  grid-template-columns: 15rem 1fr;
  margin-bottom: 1rem;
  .item_header {
    font-size: 1.2rem;
    font-weight: 300;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    .thumbnail_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .duration {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        border-radius: 0.2rem;
        padding: 0.1rem 0.4rem;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    .progress {
      width: 100%;
      background-color: $grey;
      height: 0.5rem;
      position: absolute;
      bottom: 0;
    }
    .bar {
      width: 50%;
      background-color: $orange;
      height: 0.5rem;
      position: absolute;
      bottom: 0;
    }
    .progress_point {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.5rem;
      position: absolute;
      bottom: -0.1rem;
      background-color: $orange;
    }

    .thumbnail {
      width: 100%;
    }
  }

  .right {
    padding: 0.5rem;
    .item_header {
      font-size: 1.2rem;
      margin: 0;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-weight: 600;
    }
  }
}

.pc-curriculum_theme {
  font-size: 1.2rem;
  max-width: 85rem;
  .theme_information {
    display: grid;
    grid-template-columns: 1fr 10rem;
    align-items: center;
    white-space: pre-wrap;
    background-color: $titleBackgroundColor;
    margin: 0rem;
    padding: 1rem;
    border-left: 0.3rem $grey solid;
    .information {
      display: grid;
      grid-template-rows: 3rem 1fr;
      .title {
        font-weight: 600;
        font-size: 1.6rem;
        color: $mypageTitleColor;
        cursor: pointer;
      }
      .title:hover {
        color: $mypageTitleHoverColor;
      }
    }
    .progress {
      display: grid;
      text-align: center;
      justify-content: center;
      .progress_label {
        display: flex;
        align-items: baseline;
        justify-content: center;
        column-gap: 0.5rem;
      }
      .progress_title {
        padding: 0.5rem 2rem !important;
      }
      .denominator {
        font-size: 4rem;
        font-weight: 600;
        color: $studiedColor;
      }
      .numerator {
        font-size: 1.2rem;
      }
    }
  }
  .contents_items {
    padding: 1rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
    column-gap: 1rem;
    .item {
      display: grid;
      grid-template-columns: 5rem 1fr;
      column-gap: 0.5rem;
      color: $mypageContentsTitleColor;
      align-items: baseline;
      cursor: pointer;
      .title {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .ui.circular.label {
        width: 5rem;
      }
    }
    .item:hover {
      color: $mypageContentsTitleHoverColor;
    }
  }
  .term {
    font-size: 1.2rem;
  }
  margin: 1.5rem 1.5rem;
}

.sp-curriculum_theme {
  font-size: 1.2rem;
  .theme_information {
    display: grid;
    grid-template-columns: 1fr 10rem;
    align-items: center;
    white-space: pre-wrap;
    background-color: $titleBackgroundColor;
    margin: 0rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-left: 0.3rem $grey solid;
    .information {
      display: grid;
      grid-template-rows: 2.5rem 1fr;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: 1.4rem;
        padding: 0.2rem;
      }
    }
    .progress {
      display: grid;
      text-align: center;
      justify-content: center;
      .progress_label {
        display: flex;
        align-items: baseline;
        column-gap: 0.5rem;
        justify-content: center;
      }
      .progress_title {
        padding: 0.5rem 2rem !important;
      }
      .denominator {
        font-size: 3rem;
        font-weight: 600;
        color: $studiedColor;
      }
      .numerator {
        font-size: 1.2rem;
      }
    }
  }
  .contents_items {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    .item {
      display: grid;
      grid-template-columns: 5rem 1fr;
      align-items: center;
      .status {
        display: flex;
        align-items: center;
      }
      .title {
        font-size: 1.2rem;
        font-weight: 600;
        color: $descriptionColor;
        padding: 0.2rem 1rem;
      }
    }
  }
  .term {
    font-size: 1.2rem;
  }
  margin: 0rem 1rem 1.5rem 1rem;
}

.hint_popup_icon {
  padding: 0rem 0.5rem;
  cursor: pointer;
}

.input_hint_popup_icon_background {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1rem;
  background-color: $inputHintIconBackGroundColor;
}
.input_hint_popup_icon {
  padding: 0rem 0.5rem 0rem 0.35rem;
  margin: 0rem 0rem 0.1rem 0rem !important;

  color: $inputHintIconColor;
}

.hint_popup {
  font-size: 1.2rem !important;
}

.input_hint_popup {
  font-size: 1.2rem !important;
}

.transparent_dimmer.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0);
}

.custom_category_item {
  display: grid;
  cursor: pointer;
  border-radius: 0.6rem;
  border: 0.1rem solid $grayLightColor;
  margin: 1.5rem 0;
  .thumbnail {
    position: relative;
    .completion_medal {
      position: absolute;
      z-index: 10;
      top: 0.5rem;
      left: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9rem;
      height: 2.4rem;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.4rem;
      color: white;
      grid-gap: 0.5rem;
      font-size: 1.4rem;
      font-weight: 600;
      .medal_icon {
        height: 2rem;
        align-self: flex-start;
      }
    }
  }
  .info {
    padding: 1.5rem;
    display: grid;
    .title {
      color: $brandPrimaryColor;
      font-size: 1.8rem;
    }
    .description {
      color: $grey;
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 1.4rem;
    }
  }
}

.pc-custom_category_item {
  grid-template-rows: 1fr;
  grid-template-columns: 28rem 1fr;
  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    .thumbnail_wrapper {
      height: 15rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .info {
    text-align: left;
    grid-template-rows: 5rem 1fr;
  }
}

.sp-custom_category_item {
  grid-template-columns: 1fr;
  margin: 0 0 2rem 0;

  .thumbnail {
    .thumbnail_image {
      min-height: 20rem;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
    }
  }
  .info {
    grid-gap: 0.6rem;
    .title {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
}

.level_stars {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  .level {
    font-size: 1.2rem;
    font-weight: 600;
    color: $grayDarkColor;
  }
  .stars {
    display: flex;
    align-items: center;
    justify-content: center;
    > i {
      font-size: 1.2rem !important;
      margin-left: 0.2rem !important;
    }
  }
}

.custom_input_radio_v2 {
  .item {
    padding: 0.6rem 0.5rem 0.5rem 0.5rem !important;
    border-radius: 0.4rem;
    font-size: 1.8rem;
    border: 1px transparent dashed;
    line-height: 1.5rem !important;
    label:before {
      border-color: $grayDarkColor !important;
      border-width: 0.15rem;
    }
    .choice_image {
      margin: 0.5rem 0rem;
    }
    .checkbox {
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
  .list_checked {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    font-weight: 600;
    line-height: 1.5rem !important;
    label:before {
      border-color: $brandSecondaryColor !important;
      border-width: 0.15rem;
    }
    label:after {
      background-color: $brandSecondaryColor !important;
      color: white !important;
    }
  }
  .border_dash {
    border: 1px $brandSecondaryColor dashed;
    background-color: $brandSecondaryLightColor;
  }
  .radio {
    width: 100%;
    font-size: 1.5rem !important;
  }
}

.custom_input_radio_v2.vertical {
  .ui.list.answer {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
  }
}

.inquiry_status_label_with_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
  border-radius: 1.5rem;
  margin: 0;
  grid-gap: 0.5rem;
  .dot {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
  }
  p {
    margin: 0;
    font-weight: normal;
    font-size: 1.4rem;
  }
  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: $grayMediumColor;
    color: white;
    .badge_count {
      color: white;
      margin: 0;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
}
.inquiry_status_label_with_badge.status_red {
  background-color: $systemBackgroundColor;
  .dot {
    background-color: $systemTextErrorColor;
  }
  .status_name {
    color: $systemTextErrorColor;
  }
}
.inquiry_status_label_with_badge.status_green {
  background-color: $green500Color;
  .dot {
    background-color: $greenColor;
  }
  .status_name {
    color: $greenColor;
  }
}

.inquiry_status_label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  font-size: 1.2rem;
  margin: 0;
  grid-gap: 0.5rem;
  border: 0.1rem solid black;
}

.inquiry_status_label.status_red {
  border-color: $systemTextErrorColor;
  .status_name {
    color: $systemTextErrorColor;
  }
}

.inquiry_status_label.status_green {
  border-color: $greenColor;
  .status_name {
    color: $greenColor;
  }
}

.inquiry_status_label.status_blue {
  border-color: $brandSecondaryColor;
  .status_name {
    color: $brandSecondaryColor;
  }
}

.inquiry_status_label.status_gray {
  border-color: $grey;
  background-color: $grey;
  .status_name {
    color: white;
  }
}

.inquiry_status_label.status_gray_disabled {
  border-color: $grayLightColor;
  .status_name {
    color: $grayLightColor;
  }
}

.custom_test_status_new_label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-color: $grey;
  color: white;
  border: 0.1rem solid $grey;
  max-width: 12rem;
  .icon {
    font-size: 1.4rem;
  }
}

.custom_test_status_new_label.test_passed {
  background-color: $brandSecondaryBackgroundColor;
  color: $brandSecondaryColor;
  border: 0.1rem solid $brandSecondaryBackgroundColor;
}

.custom_test_status_new_label.test_faild {
  background-color: $systemBackgroundColor;
  color: $systemTextErrorColor;
  border: 0.1rem solid $systemBackgroundColor;
}

.custom_contents_status_new_label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-color: $grey;
  border: 0.1rem solid $grey;
  max-width: 12rem;
  color: white;
}

.custom_contents_status_new_label.contents_viewing {
  background-color: white;
  border: 0.1rem solid $brandSecondaryColor;
  color: $brandSecondaryColor;
}

.custom_contents_status_new_label.contents_viewed {
  background-color: $grayLightColor;
  border: 0.1rem solid $grayLightColor;
}

.custom_package_status_new_label {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
    padding: 0.3rem 0.5rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    background-color: $grey;
    border: 0.1rem solid $grey;
    max-width: 12rem;
    color: white;
    margin: 0 auto;
  }
}

.custom_package_status_new_label.package_studying {
  > div {
    background-color: $brandSecondaryBackgroundColor;
    color: $brandSecondaryColor;
    border: 0.1rem solid $brandSecondaryBackgroundColor;
  }
}

.custom_package_status_new_label.package_studied {
  > div {
    background-color: $brandSecondaryBackgroundColor;
    color: $brandSecondaryColor;
    border: 0.1rem solid $brandSecondaryBackgroundColor;
  }
}

.custom_package_management_item_status {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.4rem;
    border: 0.1rem solid $grayLightColor;
    padding: 0.5rem;
    width: 8rem;
    text-align: center;
  }
}
.custom_package_management_item_status.draft {
  p {
    color: $brandSecondaryColor;
    border-color: $brandSecondaryColor;
  }
}
.custom_package_management_item_status.release {
  p {
    color: $greenColor;
    border-color: $greenColor;
  }
}
.custom_package_management_item_status.close {
  p {
    color: $red100Color;
    border-color: $red100Color;
  }
}
.custom_package_management_item_status.mini {
  p {
    font-size: 1rem;
    padding: 0.2rem;
    width: 4rem;
    text-align: center;
  }
}

.package_view_label {
  background-color: $packageViewLabelBackgroundColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-radius: 0.4rem;
  height: 2rem;
  width: 12.8rem;
  padding: 0rem 0.5rem;
  grid-gap: 0.5rem;
  margin: 0.5rem 0rem;
  .package_view_icon {
    width: 1.2rem;
  }
  p {
    font-size: 1rem;
  }
}
