@import "./../../assets/sass/variables.scss";
.pc-main {
  .legal_report_form {
    padding: 1rem;
    position: relative;
    .report_title {
      margin: 0.5rem 0;
      font-size: 1.6rem;
      font-weight: 600;
    }
    .theme_title {
      margin: 0.5rem 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    .report {
      font-size: 1.4rem;
    }
    .report_footer {
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding: 1rem 0;
      .input_length {
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .send_action {
        text-align: right;
      }
    }
    .finished_message {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $messageOnMaskedBackgroundColor;
      z-index: 1000;
      display: grid;
      justify-content: center;
      align-items: center;
      .finished_message_title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
  .legal_report_mypage {
    position: relative;
    padding: 0 1rem;
    .legal_report_header {
      p {
        font-weight: 600;
        padding: 0.5rem 0;
      }
    }
    .legal_report_readonly {
      border: 0.1rem solid $itemMarginBorderColor;
      border-radius: 0.3rem;
      padding: 1rem;
    }
    .report {
      font-size: 1.4rem;
    }
    .input_length {
      font-size: 1.2rem;
      padding: 0.5rem 0.5rem;
    }
    .legal_report_action {
      padding: 1rem 0;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    .report_footer {
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding: 1rem 0;
      .input_length {
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .send_action {
        text-align: right;
      }
    }
    .finished_message {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $messageOnMaskedBackgroundColor;
      z-index: 1000;
      display: grid;
      justify-content: center;
      align-items: center;
      .finished_message_title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
}

.legal_report_form_modal_content {
  .legal_report_form {
    padding: 1rem;
    position: relative;
    .report_title {
      margin: 0.5rem 0;
      font-size: 1.6rem;
      font-weight: 600;
    }
    .theme_title {
      margin: 0.5rem 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    .report {
      font-size: 1.4rem;
      min-height: 20rem;
    }
    .report_footer {
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding: 1rem 0;
      .input_length {
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .send_action {
        text-align: right;
      }
    }
    .finished_message {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $messageOnMaskedBackgroundColor;
      z-index: 1000;
      display: grid;
      justify-content: center;
      align-items: center;
      .finished_message_title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
}

.sp-main {
  .legal_report_form {
    text-align: left;
    padding: 1rem;
    position: relative;
    .report_title {
      margin: 0.5rem 0;
      font-weight: 600;
    }
    .theme_title {
      margin: 0.5rem 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    .report {
      font-size: 1.4rem;
    }
    .report_footer {
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding: 1rem 0;
      .input_length {
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .send_action {
        text-align: right;
      }
    }
    .finished_message {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $messageOnSpRecommendMaskedBackgroundColor;
      z-index: 1000;
      display: grid;
      justify-content: center;
      align-items: center;
      margin: -1rem;
      .finished_message_title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
  .legal_report_mypage {
    position: relative;
    padding: 0 1rem;
    .legal_report_header {
      p {
        font-weight: 600;
        padding: 0.5rem 0;
      }
    }
    .legal_report_body {
      .legal_report_readonly {
        border: 0.1rem solid $itemMarginBorderColor;
        border-radius: 0.3rem;
        padding: 1rem;
      }
      .report {
        font-size: 1.4rem;
      }
      .input_length {
        font-size: 1.2rem;
        padding: 0.5rem 0.5rem;
      }
    }
    .legal_report_action {
      padding: 1rem 0;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    .report_footer {
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding: 1rem 0;
      .input_length {
        font-size: 1.2rem;
        padding: 0 0.5rem;
      }
      .send_action {
        text-align: right;
      }
    }
    .report {
      font-size: 1.4rem;
    }
    .finished_message {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $messageOnMaskedBackgroundColor;
      z-index: 1000;
      display: grid;
      justify-content: center;
      align-items: center;
      .finished_message_title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
}

.fadein {
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.legal_report_group_study_individual {
  position: relative;
  .legal_report_readonly {
    border: 0.1rem solid $itemMarginBorderColor;
    border-radius: 0.3rem;
    padding: 1rem;
  }
  .report {
    font-size: 1.4rem !important;
    min-height: 10rem;
  }
  .input_length {
    font-size: 1.2rem;
    padding: 0.5rem 0.5rem;
  }
  .legal_report_action {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
  .report_footer {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    grid-gap: 1rem;
    .input_length {
      font-size: 1.6rem;
      padding: 0 0.5rem;
      color: $grayDarkColor;
      .input_finished {
        font-weight: 600;
      }
    }
    .send_action {
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      text-align: center;
      .send_button {
        background-color: $brandSecondaryColor;
        color: white;
        font-size: 1.4rem;
        padding: 1.5rem 2rem;
      }
      .close_button {
        font-size: 1.4rem;
        padding: 1.5rem 2rem;
        background-color: white;
        color: $grayDarkColor;
        border: 0.1rem solid $grayLightColor;
      }
    }
  }
  .theme_title {
    margin: 1rem 0;
    font-size: 1.4rem;
    color: $grayDarkColor;
  }
  .finished_message {
    width: 100%;
    height: 20rem;
    background-color: $messageOnMaskedBackgroundColor;
    z-index: 1000;
    display: grid;
    justify-content: center;
    align-items: center;
    .finished_message_title {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
}

.legal_report_form_individual_modal {
  padding: 1rem;
}
