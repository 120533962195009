$primaryColor: #023893;
$secondaryColor: aqua;
$contentBackgroundColor: #f5f5f5;
$hoverBackgroundColor: rgb(111 153 173 / 5%);
$errorBackgroundColor: #d9534f;
$badgeBackgroundColor: #d9534f;
$successBackgroundColor: #5cb85c;
$contentMinWidth: 850px;
$inputPlaceholderColor: #c9c9c9;
$paginationBackgroundColor: rgba(0, 170, 149, 0.2);
$headerColor: #717171;
$headerIconColor: #5f5f5f;
$headerIconTextColor: #7e7e7e;
$accountItemBorderColor: #dadada;
$accountItemHoverColor: #ededed;
$themeNameBackgroundColor: #17a0e7;
$thumbnailBorderColor: #eaeaea;
$labelColor: #717171;
$mypageSpBackGroundColor: #f8f8f8;
$mypageMenuItemBorderColor: #d4d4d4;
$mypageMenuItemTextColor: #8a8a8a;
$studyStatusMenuItemBorderColor: #d4d4d4;
$studyStatusMenuItemTextColor: #8a8a8a;
$mypageTitleColor: #262626;
$mypageTitleHoverColor: #555555;
$mypageContentsTitleColor: #3b3b3b;
$mypageContentsTitleHoverColor: #555555;
$descriptionColor: #5f5f5f;
$feedCommentColor: #f7f7f7;
$itemMarginBackGroundColor: #fcfcfc;
$itemMarginBorderColor: #d6d6d678;
$linkTextUnderLineColor: #e3e3e3;
$linkTextColor: #4183c4;
$reportBadgeBackgroundColor: #f2711c;
$pink: #e03997;
$blue: #2185d0;
$darkblue: #1a69a4;
$teal: #00b5ad;
$grey: #767676;
$orange: #f2711c;
$green: #21ba45;
$primaryFontColor: #ffffff;
$warningFontColor: #794b02;
$warningBackgroudColor: #fffaf3;
$warningBorderColor: #ccbea0;
$warningBoxShadow:
  0px 1px 3px 0px $warningBorderColor,
  0px 0px 0px 1px $warningBorderColor;
$infoFontColor: #0e566c;
$infoFontSecondaryColor: #4d9dd9;
$infoBackgroudColor: #f8ffff;
$infoBorderColor: #bedfe6;
$infoBoxShadow:
  0px 1px 3px 0px $infoBorderColor,
  0px 0px 0px 1px $infoBorderColor;
$barPink: #ffb4cf;
$fontColor: #080707;
$level1Color: #034bc4;
$level2Color: #0241ab;
$level3Color: #023893;
$level4Color: #022e79;
$level5Color: #012460;
$level6Color: #3f51b5;
$levelAverageColor: #767676;
$levelMaxColor: #f2711c;
$levelMinColor: #65b168;
$levelLabelColor: #7f7f7f;
$studiedColor: #4d9dd9;
$studyingColor: #00b5ad;
$notStudyColor: #9e9e9e;
$basicFontColor: rgba(0, 0, 0, 0.87);
$titleBackgroundColor: #ededed8f;
$unansweredQuestionTitileColor: #d9534f26;
$unansweredQuestionTitileTextColor: #9f3a38;
$activeTableCellBackgroundColor: rgba(57, 114, 114, 0.15);
$checkedRowColor: #ededed8f;
$headerBannerColor: #fff8db;
$announceListStartDateColor: #081220;
$feedbackBorderColor: #081220;
$messageOnMaskedBackgroundColor: #ffffffcc;
$messageOnSpRecommendMaskedBackgroundColor: #7e7e7ed9;
$userInfoTextColor: #202020;
$userInfoHeaderBackGroundColor: #ebf7fd;
$userInfoSelectInactiveColor: #cccccc;
$inputErrorBorderColor: #e0b4b4;
$inputErrorBackGroundColor: #fff6f6;
$userInfoSegmentBackGroundColor: #f3f6f8;
$fixedRecommendBackgroundColor: #000000b3;
$tutorialPrimaryColor: #00a0e9;
$tutorialFontColor: #212121;
$tutorialProgressMypageBackGroundColor: #00a0e91a;
$tutorialProgressMypageBorderColor: #bbddf6;
$tutorialProgressMypageCloseButtonIconColor: #4f7998;
$tutorialProgressMypageCloseButtonBackGrorundColor: #e5f5fd;
$tutorialLinkButtonDsiabledColor: #cccccc;
$tutorialWarningMessageColor: #ff5f5f;
$userRecommendedPasswordLabelColor: #2c89d1;
$userRegisterSucceededItemsBorderColor: #dcdcdc;
$inputHintIconColor: #636364;
$inputHintIconBackGroundColor: #e0e1e2;
$imgCircleBorderColor: #f7f7f7;
$negiraiPrimaryColor: #00a0e9;
$negiraiBackGroundColor: #ebf7fc;
$brandPrimaryColor: #003894;
$brandPrimaryPlus10Color: #034bc4;
$brandPrimaryLightColor: #e5ebf4;
$brandPrimaryMinus5Color: #022e79;
$brandPrimaryMinus10Color: #012460;
$brandPrimaryMinus15Color: #011b46;
$brandSecondaryColor: #00a0e9;
$brandSecondaryLightColor: #e5f5fd;
$brandSecondaryPlus10Color: #1fb9ff;
$brandSecondaryMinus10Color: #007eb8;
$grayMediumColor: #888888;
$grayDarkColor: #555555;
$grayLightColor: #cccccc;
$monoGrayWhitish: #eeeeee;
$blackColor: #222222;
$greenColor: #43a047;
$green200Color: #65b168;
$green500Color: #f5faf5;
$red100Color: #f44336;
$errorRedColor: #f44336;
$brandSecondaryBackgroundColor: rgba(#00a0e9, 0.1);
$borderInfoColor: #bbddf6;
$errorBackGroundColor: #fff6f6;
$errorFontColor: #912d2b;
$borderErrorColor: #e0b4b4;
$systemTextErrorColor: #b71c1c;
$systemBackgroundColor: #ffebee;
$contentsBackgroundColor: #202020;
$inquiryStatusLabelPink: #ff6e6e;
$modalActionsBackgroundColor: #f9fafb;
$modalActionsBorderColor: #22242626;
$expirationDateBannerBackgroundColor: #fff8cc;
$expirationDateBannerBorderColor: #c2a800;
$expirationDateBannerTextColor: #a38700;
$packageViewLabelBackgroundColor: #e65100;
$tableInnerBorderColor: #2224261a;

$fontSizeTiny: 1.3rem;
$fontSizeSmall: 1.4rem;
$fontSizeMedium: 1.6rem;
$fontSizeLarge: 2.4rem;
